import { Mixins, Component } from "vue-property-decorator";
import ModelMixin from "@/mixins/ModelMixin";
import type { VueClass } from "vue-class-component/lib/declarations";
import { PaymentTerm, PaymentTerms } from "@planetadeleste/vue-mc-gw";

const TypedModelMixin = ModelMixin as unknown as VueClass<
  ModelMixin<PaymentTerm, PaymentTerms>
>;

@Component
export default class PaymenttermsMixin extends Mixins(TypedModelMixin) {
  obCollection = new PaymentTerms();
  obModelClass = PaymentTerm;
  obCollectionClass = PaymentTerms;
  sRouteName = "paymentterms.list";

  created() {
    this.onCreated();
  }
}
