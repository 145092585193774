<template>
  <sheet-drawer v-model="drawer" @close="cancel">
    <v-form v-if="obModel && drawer" class="fill-height" @submit.prevent>
      <gw-form-observer
        :hide-save-action="!obModel.id"
        :loading="isLoading"
        :dirty="isDirty"
        @cancel="cancel"
        @save="save"
      >
        <settings v-model="obModel" />
        <template v-if="!obModel.id" #bottom-actions="{ invalid }">
          <payment-terms-confirm-dialog
            :disabled="invalid"
            @apply="onApplyAndSave"
          />
        </template>
      </gw-form-observer>
    </v-form>
  </sheet-drawer>
</template>

<script lang="ts">
import { Component, Mixins, PropSync } from "vue-property-decorator";
import PaymenttermsMixin from "@/modules/paymentterms/mixins/PaymenttermsMixin";
import SheetDrawer from "@/layout/components/main/SheetDrawer.vue";
import Settings from "@/modules/paymentterms/components/tabs/Settings.vue";
import PaymentTermsConfirmDialog from "@/modules/paymentterms/components/PaymentTermsConfirmDialog.vue";

@Component({
  components: {
    PaymentTermsConfirmDialog,
    SheetDrawer,
    Settings,
  },
})
export default class PaymenttermsForm extends Mixins(PaymenttermsMixin) {
  @PropSync("open", { type: Boolean, default: false }) drawer!: boolean;

  onApplyAndSave(sApplyTo: "customer" | "provider" | "both" | null) {
    this.obModel.unset("applyto");

    if (sApplyTo) {
      this.obModel.set("applyto", sApplyTo);
    }

    this.save();
  }
}
</script>
